html {
    overflow-y: auto;
    body {
        margin: 0;
        font:
            14px/1.5 "Microsoft YaHei",
            "PingFang SC",
            "Helvetica Neue",
            Helvetica,
            Arial,
            "Hiragino Sans GB",
            "Heiti SC",
            "WenQuanYi Micro Hei",
            sans-serif;
        color: #333;
        a {
            text-decoration: none;
            color: #333;
        }
        @keyframes background-big {
            0% {
                background-size: 100% 100%;
            }

            100% {
                background-size: 110% 110%;
            }
        }
    }
}
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: rgba(125, 125, 125, 0.7);
    -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
    width: 5px;
    background-color: rgba(125, 125, 125, 0.7);
    -webkit-border-radius: 6px;
}
